import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, useMediaQuery, Table
} from '@mui/material';
import {
  TableContainerWrapper, TableComponent, ErpHeader, FooterGrid,
  MainTableHead, MobileContainer, TableGrid
} from './orders.styles';
import ReturnRow from './returnRow';
import OrdersFilter from './ordersFilter';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import { useStateValue } from '../../../../providers/stateProvider';
import { JSONParse } from '../../../../utils/json';
import { addHyphen } from '../../../shared/methods';
import TablePagination from '../../../shared/tablePagination';

const headers = [
  'Order Date', 'Order Number', 'Customer Category', 'Order Value', 'Sorted Value', 'Affiliate', 'Estimated Delivery', 'Order Status'
];

const quoteHeaders = [
  'Order Date', 'Order Number', 'Value', 'Order Status'
];

const Orders = ({
  state, data, loading, error, handleSetFilter, handleFilterChange, totalCount,
  handleClear, handleDateSubmit, refetch, rfqOrders,
  pageCount, pageNumber, setPageCount, setPageNumber
}) => {
  const [{ user: { isSupplyChain } }] = Object.values(useStateValue());
  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);
  const isSmall = useMediaQuery('(max-width:991px)');
  const { status: activeStatus } = state;

  const reformatAddress = (str) => JSONParse(str.replace(/\"*([\w&.+×'÷=/-<>\[\]()])+\"/g, (m, _) => m.replaceAll("'", '`')));

  const returnHeaders = () => {
    const currentHeaders = activeStatus === 'quoteReq' ? quoteHeaders : headers;
    return currentHeaders.map((header) => (
      <ErpHeader key={header} data-testid={`mp-uat-ordersPage-${addHyphen(header)}`}>
        {header}
      </ErpHeader>
    ));
  };

  const { openAndClosedOrders = [] } = data ?? '';
  const xeroStatuses = ['authorised', 'paid', 'voided'];

  const openAndCloseOrderItems = openAndClosedOrders.map((openOrder) => {
    const {
      business: { name: businessName, businessCategory }, paymentSummary, id,
      supplierorderSet, metaStatus, meta, status, parentOrderId,
      datePlaced,
    } = openOrder;
    const { categoryName = '' } = businessCategory ?? {};
    const { total: orderTotal, savings: orderSavings, main_total: mainOrderTotal } = JSONParse(paymentSummary);
    const { xero_status: xeroStatus, xero_approval: approved } = reformatAddress(meta);

    const suppliers = supplierorderSet.map(({ supplier: supplierItem }) => supplierItem);
    if (!isSupplyChain || (isSupplyChain && xeroStatuses.includes(xeroStatus))) {
      return {
        businessName, datePlaced, orderTotal, orderSavings, id, suppliers, metaStatus, status, parentOrderId, approved, categoryName, mainOrderTotal
      };
    }
    return null;
  });

  const quoteOrderItems = rfqOrders.map((rfq) => {
    const {
      id, datePlaced, paymentSummary, meta
    } = rfq;
    const { total } = JSONParse(paymentSummary);
    const { rfq_status: status } = JSONParse(meta);

    return {
      id, datePlaced, status, total
    };
  });

  if (error) return <div>{error.message}</div>;
  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  const orderItems = activeStatus === 'quoteReq' ? quoteOrderItems : openAndCloseOrderItems;

  return !isSmall ? (
    <>
      <TableContainerWrapper>
        <OrdersFilter
          state={state}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleDateSubmit={handleDateSubmit}
          activeStatus={activeStatus}
        />
        <TableGrid item container>
          {loading ? <ProductsTableLoader /> : (
            <TableComponent item container aria-label="Orders table">
              <Table style={{ width: '100%' }} data-testid="mp-uat-manageOrders">
                <MainTableHead item container>
                  {returnHeaders()}
                  {activeStatus === 'open' && (
                    <ErpHeader data-testid="mp-uat-ordersPage-action">Action</ErpHeader>
                  )}
                </MainTableHead>
                {orderItems && (
                  <TableBody>
                    {orderItems?.map((order, index) => (
                      <ReturnRow
                        key={order?.id}
                        row={order}
                        user={isSupplyChain}
                        index={index}
                        refetch={refetch}
                        activeStatus={activeStatus}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableComponent>
          )}
        </TableGrid>
      </TableContainerWrapper>
      <FooterGrid item container>
        <TablePagination
          total={totalCount}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
    </>
  )
    : (
      <MobileContainer>
        <OrdersFilter
          state={state}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleMobile={handleMobileFilter}
          orderItems={orderItems}
          loading={loading}
          handleClear={handleClear}
          count={totalCount}
          refetch={refetch}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </MobileContainer>
    );
};
Orders.propTypes = {
  state: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleDateSubmit: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  rfqOrders: PropTypes.instanceOf(Array),
};

Orders.defaultProps = {
  state: {},
  data: {},
  loading: false,
  rfqOrders: []
};

export default Orders;
