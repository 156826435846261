import { Grid, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from '../../../assets/svgs';

export const ItemGrid = styled(Grid)`
  cursor: pointer;
  margin-bottom: 1.5rem;
  height: 150px;
  box-shadow: 0.5px solid #E7E8E9;
  border-radius: 12px;

  @media(max-width: 991px) {
    margin-bottom: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border: 0.5px solid #E7E8E9;
    border-radius: 20px;
  }
`;

export const ItemContent = styled(Grid)`
  padding: 20px;
  height: 100%;
  @media(max-width: 991px) {
    padding: 0px 30px;
    height: 100%;
  }
`;

export const ActionItem = styled(Grid)`
  display: flex;
  height: 86%;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

export const ImgContent = styled(Grid)`
  height: 100%;
  width: 100%;
  background: #F3F9FF;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 991px) {
    height: 90%;
    border-radius: 25px;
  }
`;

export const ItemTypo = styled(Typography)`
  margin-bottom: 20px;
  font-size: 33px;
  @media(min-width: 992px) {
    font-size: 14px;
    margin: 10px 0 15px 0;
    color: #606060;
    font-weight: 600;
  }
`;

export const ReqTypo = styled(Typography)`
  font-weight: 400;
  font-size: .875rem;
  color: #606060;
`;

export const DetailContainer = styled('div')`
  display: flex;
  margin-bottom: 15px;
  @media(max-width: 991px) {
    margin-bottom: 25px;
  }
`;

export const TypeImg = styled('img')`
  height: 50px;
  filter: hue-rotate(135deg) saturate(2) brightness(1.1);

  @media(min-width: 992px) {
    height: 20px;
  }
`;

export const DetailButton = styled(Button)`
  margin-top: 4rem;
  border: 1px solid #235a91;
  width: 10.313rem;
  height: 2.313rem;
  font-weight: 500;
  font-size: .75rem;
  color: #606060;
`;

export const CardLogo = styled(Logo)`
  width: 100%;
  height: 50%;
`;
