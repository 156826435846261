import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './tabButtons.styles';

const TabsButtons = ({ active, handleStatusValueChange }) => (
  <StatusesGrid container item>
    <UnderlineGrid
      item
      container
      onClick={() => handleStatusValueChange('orders')}
      active={active === 'orders'}
      width="8%"
      className="mpAdmin-uat-ordersPageTab-orders"
    >
      <Typo active={active === 'orders'}>Orders</Typo>
    </UnderlineGrid>
    <UnderlineGrid
      item
      container
      onClick={() => handleStatusValueChange('quoteRequests')}
      active={active === 'quoteRequests'}
      width="12%"
      className="mpAdmin-uat-ordersPageTab-quoteRequests"
    >
      <Typo active={active === 'quoteRequests'}>Quote Requests</Typo>
    </UnderlineGrid>
    <UnderlineGrid container width="80%" marginTop="1.3rem">
      <Typo>{' '}</Typo>
    </UnderlineGrid>
  </StatusesGrid>
);
TabsButtons.propTypes = {
  active: PropTypes.string,
  handleStatusValueChange: PropTypes.func.isRequired
};

TabsButtons.defaultProps = {
  active: '',
};

export default TabsButtons;
