import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, Table, TableBody, TableContainer, TableHead, TableRow
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { QUERY_ORDERS_TO_DISPATCH } from '../../../queries/affiliates';
import { DISPATCH_MULTIPLE_ORDERS } from '../../../mutations/orders';
import { JSONParse } from '../../../utils/json';
import { kwiksDispatchOrder } from '../../../utils/kwiks';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, TextWrapper, TableOptionsTab, PaymentTableTitle,
  MenuButton, HeaderButtons, PayButton, PayButtonText,
  OrderGridWrapper, CheckBoxButton, CustomCheckbox, DispatchContainer, CDialog, HeaderSubText, HeaderCell, DialogActionButtonText, CloseButton, HeaderText
} from './affiliatesDetailsContainer.styles';
import OrderCard from '../../orders/supplier-orders/orderSelectCard';

import SelectAllImage from '../../../assets/images/select.png';
import ReturnRow from './returnRow';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AffiliatesDetailsContainer = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [loadingRequest, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [dispatchOrders] = useMutation(DISPATCH_MULTIPLE_ORDERS);
  const [errorDialog, setErrorDialog] = useState(false);
  const [dispatchErrors, setDispatchErrors] = useState([]);

  const headers = [
    'Product Name', 'Qty Requested', 'Qty in Stock',
  ];

  const toggleItemInCart = (cartItem) => {
    const { orderId } = cartItem;
    let newCart = [...cart];
    const existingOrderInCart = newCart.find((oneCart) => oneCart.orderId === orderId);
    if (existingOrderInCart) {
      newCart = newCart.filter((oneCart) => oneCart.orderId !== orderId);
    } else {
      newCart = [...newCart, cartItem];
    }
    setCart(newCart);
  };

  const {
    loading, error, data, refetch
  } = useQuery(QUERY_ORDERS_TO_DISPATCH, {
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  /**
   * map the recieved data into a more readable form.
  */
  const filteredData = data.allOrdersToDispatch.map((oneData) => {
    const products = oneData.supplierorderSet.reduce((a, b) => {
      const { orderproductSet } = b;
      return [...a, ...orderproductSet];
    }, []).filter(({ quantityRecieved }) => quantityRecieved);
    const totalPrice = products.reduce((a, b) => a + Number(b.price), 0);
    const { contacts, name } = oneData.deliveryOutlet;
    const {
      address_line_1: address,
      mobile_number: number
    } = JSONParse(contacts);
    return {
      ...oneData,
      products,
      totalPrice,
      payload: {
        address,
        number,
        name,
        orderId: oneData.id
      }
    };
  });

  const handleSelect = (oneOrder) => {
    const ids = cart.map(({ orderId }) => orderId);
    return ids.includes(oneOrder);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const newSelections = filteredData.map((product) => product.payload);
      return setCart(newSelections);
    }
    return setCart([]);
  };

  /**
   * A function used to dispatch the current state of the cart
  */
  const dispatchCart = async () => {
    try {
      setLoading(true);
      const productIds = cart.map(({ orderId }) => orderId);
      const jobId = await kwiksDispatchOrder(cart);
      await dispatchOrders({
        variables: {
          orderIds: productIds,
          jobId
        }
      }).then(({ data: responseData }) => {
        const { products, message } = responseData.dispatchKwikOrders;
        if (products && products?.length) {
          setDispatchErrors(products);
          setErrorDialog(true);
          throw Error(message);
        }
      });
      toast.success('The orders have been successfully dispatched.');
    } catch (err) {
      // console.log('there was an error submitting kwiks api');
    } finally {
      setLoading(false);
      refetch();
    }
  };

  const closeDialogModal = () => {
    setErrorDialog(false);
  };

  return (
    <MainContent>
      <DispatchContainer>
        <MenuButton container item sm={12} onClick={() => navigate('/orders-admin')}>
          <KeyboardArrowLeft fontSize="medium" />
          back
        </MenuButton>
        <OrderGridContainer container>
          <TitleGridContainer container item sm={12}>
            <TitleTextGridContainer item>
              <TextWrapper>
                <PrimaryTitle sm={5} variant="h5">
                  Dispatch Orders
                </PrimaryTitle>
                <SecondaryTitle style={{ marginTop: '7px' }}>
                  Bulk Dispatch Orders
                </SecondaryTitle>
              </TextWrapper>
            </TitleTextGridContainer>
            <HeaderButtons item sm={5} justifyContent="flex-end">
              <PayButton
                disabled={!cart.length}
                onClick={dispatchCart}
              >
                <PayButtonText>
                  {
                    loadingRequest ? 'loading...' : 'Dispatch Selected Orders'
                  }
                </PayButtonText>
              </PayButton>
            </HeaderButtons>
          </TitleGridContainer>
        </OrderGridContainer>

        <TableOptionsTab container alignItems="center">
          <Grid item>
            <PaymentTableTitle>
              {`${filteredData.length} Order(s)`}
            </PaymentTableTitle>
          </Grid>
          <Grid>
            <CheckBoxButton>
              <CustomCheckbox
                src={SelectAllImage}
                checked={filteredData.length === cart.length}
                onClick={() => handleSelectAll()}
              />
              Select all
            </CheckBoxButton>
          </Grid>
        </TableOptionsTab>

        <OrderGridWrapper container sm={12}>
          {
            (filteredData && filteredData.length)
              ? filteredData.map((oneOrder) => (
                <Grid sm={12} md={4} key={oneOrder.id}>
                  <OrderCard
                    key={oneOrder.id}
                    order={oneOrder}
                    toggleItemInCart={toggleItemInCart}
                    active={handleSelect(oneOrder?.id)}
                  />
                </Grid>
              ))
              : <p>There are currently no orders ready to be dispatched.</p>
          }
        </OrderGridWrapper>
        {/* <GoToTop /> */}
        <CDialog
          open={errorDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeDialogModal()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <HeaderText>Issues Encountered</HeaderText>
            <HeaderSubText>Requested quantities below are higher than Quantity in stock</HeaderSubText>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <TableContainer>
                <Table aria-label="orders table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <HeaderCell key={header}>{header}</HeaderCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dispatchErrors?.map((dispErr) => (
                      <ReturnRow
                        key={dispErr?.id}
                        dispErr={dispErr}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <CloseButton
              onClick={() => closeDialogModal()}
            >
              <DialogActionButtonText>
                Close
              </DialogActionButtonText>
            </CloseButton>
          </DialogActions>
        </CDialog>
      </DispatchContainer>
    </MainContent>
  );
};

export default AffiliatesDetailsContainer;
