import React from 'react';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import {
  Paper, Grid, IconButton, Typography,
  InputBase,
  TableCell
} from '@mui/material';
import { SyncIcon } from '../../../../assets/svgs';

const sm = '960px';

export const SupplierDetailCard = styled(Paper)`
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  width: 95vw;
  height: 450px;
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  @media (min-width: 992px) {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const PaperContainer = styled(Paper)`
  padding: 1rem 2rem;
  margin-top: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 7px;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  color: rgba(36, 41, 46, 0.87);
  text-align: left;
  border: 0;
`;

export const BackText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const GridButton = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
`;

export const SupplierDetailCardWrapper = styled(Grid)`
  @media (max-width: ${sm}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const OrderDetailSupplierInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const OrderDetailSupplierInfoLogo = styled('img')`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  @media (max-width: ${sm}) {
    width: 20px;
    height: 20px;
  }
`;

export const OrderDetailSupplierInfoName = styled('p')`
  color: #424242;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  @media (min-width: 992px) {
    font-size: 17px;
    margin-top: 0px;
  }
`;

export const SupplierDetailCardActionButton = styled('button')`
  color: #A3A3A3;
  font-size: 18px;
  margin: 0;
  background-color: white;
  border: none;
  text-decoration: underline;
  @media (max-width: ${sm}) {
    font-size: 10px;
  }
`;

export const OrderDeliveryNotesText = styled('p')`
  color: #424242;
  font-size: 20px;
  @media (max-width: ${sm}) {
    font-size: 12px;
  }
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
  margin-right:0px;
  justify-content: center;
  @media (min-width: 2560px) {
    margin-right: 22%;
  }
  @media (max-width: 1199px) {
    margin-left: 7.5rem;
    margin-right: 8rem;
  }
  @media (max-width: 991px) {
    margin: 0 13rem;
  }
`;

const primaryStyles = css`
  background-color: #FCED50;
  color: #424242;
  &:hover {
    background-color: #D2C647;
  }
`;

const secondaryStyles = css`
  background-color: none;
  color: #424242;
  border: 1px solid black;
  margin-right: .9rem;
  @media(min-width: 1440px) {
    margin-right: 2rem;
  }
`;

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    default:
      return primaryStyles;
  }
};

export const OrderButton = styled(({ color, ...otherProps }) => (
  <IconButton {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-radius: 12px;
  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }
  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 20px;
    border-radius: 10px;
  }
  ${getButtonStyles}
`;

export const OrderButtonText = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 400;
  @media(min-width: 992px) {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ProductContainer = styled('div')`
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ECECEC;
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding: 35px 0;
    margin: 0 10px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const ProductDetail = styled('div')`
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    width: 350px;
  }
`;

export const ProductDetailImage = styled('img')`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;
  @media (min-width: 992px) {
  width: 100px;
  max-height: 100px;
  margin-right: 10px;
  }
`;

export const ProductDetailBrandNameText = styled('p')`
  font-weight: 700;
  font-size: 14px;
  color: #24292E;
  margin: 0;
  text-transform: title-case;
  margin-bottom: 15px;
  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #606060;
  font-weight: 500;
  font-size: 12px;

  @media (max-width: 991px) {
    color: #868686;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 300;
  }
`;

export const SubtotalGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 16px;
`;

export const SubtotalText = styled('div')`
  width: 14rem;
  margin-top: 2rem;
  border-top: 1px solid #E7E8E9;
  padding-top: 1rem;
  text-align: center;
`;

export const ShowMd = styled(Grid)`
  position: relative;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const ProductDetailItemPriceText = styled('p')`
  margin: 0;
  margin-right: auto;
  color: #424242;
  line-height: 23px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  @media (max-width: 991px) {
    font-size: 30px;
    margin-right: 0;
  }
`;

export const ProductDetailMainContainer = styled('div')`
  width: 145px;
  margin-right: 20px;
  @media (min-width: 2560px) {
    margin-right: 12%;
  }
`;

export const ProductDetailPriceMobileContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 991px) {
    height: 15rem;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0px;

  @media (min-width: 992px) {
    justify-content: space-between;
  }
`;

export const SaveContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
`;

export const Sync = styled(SyncIcon)`
  margin-right: 7px;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const CustomInputNumberBase = styled(InputBase)`
  border: 0.6px solid #E7E8E9;
  width: 40%;
  border-radius: 10px;
  font-size: .938rem;
  color: #606060;
  line-height: 3.2;
  font-weight: 600;

  & .MuiInputBase-input {
    padding: 10px 10px;
    padding-right: 5px;
    line-height: normal;
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 0px;
    margin-left: 5px;
  }

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const CartGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  background-color: #f3f9ff;
  margin-bottom: 25rem;
  @media (min-width: 992px) {
    padding: 1px 4rem 3rem 4rem;
    margin-bottom: 0rem;
  }
`;
