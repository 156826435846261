import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { StatusColor, StatusText } from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { parseJson } from '../../affiliates/utils';
import ActionPopper from './actionPopper';
import { Dots } from '../../../assets/svgs';

const ReturnRow = ({
  row
}) => {
  const navigate = useNavigate();
  const {
    id, datePlaced, business, paymentSummary, meta
  } = row;
  const [action, setAction] = useState(null);

  const paymentValue = parseJson(paymentSummary);
  const total = paymentValue?.total;
  const affiliateName = business?.name;
  const { rfq_status: rfqStatus } = parseJson(meta);

  const renderDate = moment(datePlaced).format('DD/MM/YY (h:mm A)');
  const value = currencyFormatter(total);

  const getColorGrid = (bkg, name) => (
    <Box style={{ display: 'flex' }}>
      <StatusColor bkg={bkg} />
      <StatusText>{name}</StatusText>
    </Box>
  );

  const getStatus = () => {
    switch (rfqStatus) {
      case 'PENDING_AFFILIATE_APPROVAL':
        return getColorGrid('#A904A3', 'Pending Affiliate Approval');
      case 'PENDING_FINANCE_APPROVAL':
        return getColorGrid('#00A0B5', 'Pending Finance Approval');
      case 'AWAITING_INBOUND':
        return getColorGrid('#0900B5', 'Awaiting Inbound');
      case 'COMPLETED':
        return getColorGrid('#00B588', 'Completed');
      case 'ORDER_CANCELED':
        return getColorGrid('#FF0F0F', 'Cancelled');
      default:
        return getColorGrid('#F29053', 'Pending price confirmation');
    }
  };

  const handleRoute = () => navigate(`/rfq-details/${id}`);
  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };
  const handleAction = (event) => {
    handleRoute();
    handleOptions(event);
  };

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '200px' }}>{renderDate}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '200px' }} onClick={handleRoute}>{`#${id}`}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{`₦ ${value}` || '-' }</TableColumnRowText>
      <TableColumnRowText style={{ width: '320px' }}>{affiliateName || '-'}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{getStatus()}</TableColumnRowText>
      <TableColumnRowText style={{ width: '220px' }}>
        <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
        <ActionPopper
          action={action}
          handleAction={handleAction}
          row={row}
          handleClose={handleCloseModal}
        />
      </TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
