import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ItemGrid, ItemContent, ItemTypo, ReqTypo, DetailButton,
  DetailContainer, TypeImg, ImgContent, ActionItem, CardLogo
} from './quoteReqReturnRow.styles';
import TotalLogo from '../../../assets/images/marketOrder/newTotal.png';
import { CustomEye } from '../../../assets/svgs';
import { JSONParse } from '../../../utils/json';

const QuoteReqReturnRow = ({ orderItem, index }) => {
  const navigate = useNavigate();

  const {
    id, paymentSummary, datePlaced: date, meta
  } = orderItem;

  const dateFormat = 'l h:mm';
  const datePlaced = date ? moment(date).format(dateFormat) : '';
  const { total } = JSONParse(paymentSummary);

  const safeParseJSON = (string, fallback = {}) => {
    try {
      const sanitizedString = string
        .replace(/'/g, '"')
        .replace(/None/g, 'null')
        .replace(/\bTrue\b/g, 'true')
        .replace(/\bFalse\b/g, 'false');
      return JSON.parse(sanitizedString);
    } catch (error) {
      console.error(`Error parsing JSON string: ${string}`, error);
      return fallback;
    }
  };

  const parsedMeta = safeParseJSON(meta);
  const priceUpdated = parsedMeta?.price_updated || false;
  const amount = priceUpdated ? currencyFormatter(total) : 'Price is Pending';

  const handleViewDetails = () => {
    navigate(`/quote-details/${id}`);
  };

  return (
    <ItemGrid
      container
      component={Paper}
      elevation={0}
      sx={{ border: '1px solid #E7E8E9' }}
      className={`mpFe-uat-orders-cards-${index}`}
    >
      <ItemContent item xs={12} md={1.5}>
        <ImgContent>
          <CardLogo />
        </ImgContent>
      </ItemContent>
      <ItemContent item xs={12} md={6.5}>
        <ItemTypo>
          #
          {id}
        </ItemTypo>
        <DetailContainer>
          <ReqTypo>
            Requested on
            {' '}
            {datePlaced}
          </ReqTypo>
        </DetailContainer>
        <DetailContainer>
          <TypeImg src={TotalLogo} alt="total" />
          &nbsp; &nbsp;
          <ReqTypo>
            New Total: &nbsp;
            <b>
              {priceUpdated && '₦'}
              {amount}
            </b>
          </ReqTypo>
        </DetailContainer>
      </ItemContent>
      <ItemContent item xs={12} md={4}>
        <ActionItem container>
          <DetailButton onClick={handleViewDetails}>
            <CustomEye sx={{ fontSize: '1rem' }} />
            &nbsp;
            View Quote Details
          </DetailButton>
        </ActionItem>
      </ItemContent>
    </ItemGrid>
  );
};

QuoteReqReturnRow.propTypes = {
  orderItem: PropTypes.shape(Object),
  index: PropTypes.number.isRequired
};

QuoteReqReturnRow.defaultProps = {
  orderItem: {},
};

export default QuoteReqReturnRow;
