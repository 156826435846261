import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import CustomNewTextField from '../../shared/CustomNewTextField';
import {
  FiltersGrid, SetFilter, FilterContainer, MenuItems, FilterText
} from './ordersFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { useStateValue } from '../../../providers/stateProvider';

const OrdersFilter = ({
  state, handleFilterChange, handleSetFilter, setState
}) => {
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const orderStatus = [
    { name: 'All RFQ Orders', value: 'all' },
    { name: 'Pending price confirmation', value: 'PENDING_PRICE_CONFIRMATION' },
    { name: 'Pending Affiliate Approval', value: 'PENDING_AFFILIATE_APPROVAL' },
    { name: 'Pending Finance Approval', value: 'PENDING_FINANCE_APPROVAL' },
    { name: 'Awaiting Inbound', value: 'AWAITING_INBOUND' },
    { name: 'Cancelled', value: 'ORDER_CANCELED' },
    { name: 'Completed', value: 'COMPLETED' }
  ];

  const FilterFields = [
    { name: 'affiliate', label: 'Affiliate' },
  ];

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD')
    }));
  };

  return (
    <FilterContainer item container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        <CustomNewTextField
          field={{ name: 'orderNo', label: 'Order Number' }}
          state={state}
          handleChange={handleFilterChange}
          style={{ width: '22%' }}

        />
        <CustomNewTextField
          field={{ name: 'orderStatus', label: 'Order Status' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '22%' }}
          maxHeight="20rem"
          marginLeft="2.5rem"
        >
          {orderStatus.map(({ name, value }) => (
            <MenuItems key={value} value={value}>
              {name}
            </MenuItems>
          ))}
        </CustomNewTextField>
        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '22%' }}
          />
        ))}
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{ height: '47px', border: '1px solid #bababa' }}
          />
        </Grid>
        <SetFilter
          data-testid="setFilter"
          type="tertiary"
          onClick={handleSetFilter}
        >
          <FilterText>Set Filter</FilterText>
        </SetFilter>
      </FiltersGrid>
    </FilterContainer>
  );
};

OrdersFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  setState: PropTypes.isRequired
};

OrdersFilter.defaultProps = {
  state: {},
};

export default OrdersFilter;
