import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Grid, TableBody
} from '@mui/material';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CardWrapper, PageTitleText, PageTitleSubText,
  SubTotalPrice, TableWrapper, TableComponent, Header,
  BackText, CartGridContainer, PageTitleContainer, TableHeader, MainTableRow, MainContent,
  KeyboardArrowLeft, BackArrowIconContainer, CartGridContainerHeader, GrandTotalBox, GrandTotaltext,
} from './quoteDetails.styles';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';
import ReturnRow from './returnRow';
import { GET_RFQ_ORDERS } from '../../../../queries/orders';

const initialState = {
  products: [],
  subtotal: 0
};
const QuoteDetails = () => {
  const [state, setState] = useState(initialState);

  const { id } = useParams();
  const navigate = useNavigate();

  const transformOrderData = (supplierOrders, priceUpdated) => {
    if (!supplierOrders || !Array.isArray(supplierOrders)) return [];

    return supplierOrders.reduce((products, order) => {
      const orderProducts = order.orderproductSet.map((product) => {
        const price = priceUpdated ? `₦ ${currencyFormatter(product.price)}` : 'Price is Pending';
        const unitCost = priceUpdated ? `₦ ${currencyFormatter(product.unitCost)}` : 'Price is Pending';
        const rawPrice = priceUpdated ? Number(product.price) : 0;
        return {
          ...product.product,
          name: product.name,
          quantity: product.quantity,
          price,
          unitCost,
          rawPrice,
        };
      });
      return [...products, ...orderProducts];
    }, []);
  };

  const safeParseJSON = (string, fallback = {}) => {
    try {
      const sanitizedString = string
        .replace(/'/g, '"')
        .replace(/None/g, 'null')
        .replace(/\bTrue\b/g, 'true')
        .replace(/\bFalse\b/g, 'false');
      return JSON.parse(sanitizedString);
    } catch (error) {
      console.error(`Error parsing JSON string: ${string}`, error);
      return fallback;
    }
  };

  const {
    data, loading, error
  } = useQuery(GET_RFQ_ORDERS, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  const orderDetail = data?.rfqOrders;
  const orderMeta = orderDetail?.[0]?.meta;
  const parsedMeta = safeParseJSON(orderMeta);
  const priceUpdated = parsedMeta?.price_updated || false;
  const supplierOrders = orderDetail?.[0]?.supplierorderSet || [];
  const products = transformOrderData(supplierOrders, priceUpdated);
  const calculateSubtotal = () => products.reduce((total, product) => {
    const price = priceUpdated ? Number(product.rawPrice) : 0;
    return total + price;
  }, 0);

  const subtotal = `₦ ${currencyFormatter(calculateSubtotal())}`;

  useEffect(() => {
    setState((prevState) => ({ ...prevState, products, subtotal }));
  }, [products]);

  if (error) return `Error! ${error.message}`;

  return (
    <MainContent>
      {loading ? <NewOrdersLoader column={5} row={4} />
        : (
          <CartGridContainer>
            <CartGridContainerHeader container>
              <Grid xs={12}>
                <BackArrowIconContainer onClick={() => navigate(-1)}>
                  <KeyboardArrowLeft />
                  <BackText>Back</BackText>
                </BackArrowIconContainer>
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <PageTitleContainer>
                  <PageTitleText>
                    Requested Quote Details
                  </PageTitleText>
                  <PageTitleSubText>
                    View details of your requested products list
                  </PageTitleSubText>
                </PageTitleContainer>
              </Grid>
            </CartGridContainerHeader>

            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item container lg={12}>
                <CardWrapper elevation={2}>
                  <TableWrapper>
                    <TableComponent aria-label="affiliates table">
                      <TableHeader>
                        <MainTableRow>
                          <Header style={{ paddingLeft: '1rem' }}> Product Items</Header>
                          <Header> Pack Size </Header>
                          <Header> Quantity Requested </Header>
                          <Header> Unit Cost</Header>
                          <Header>Price</Header>
                        </MainTableRow>
                      </TableHeader>
                      <TableBody>
                        {products.map((productItem) => (
                          <ReturnRow
                            key={productItem}
                            product={productItem}
                          />
                        ))}
                      </TableBody>
                    </TableComponent>
                  </TableWrapper>
                  <GrandTotalBox container>
                    <Grid item xs={6}>
                      <GrandTotaltext>
                        Subtotal
                      </GrandTotaltext>
                    </Grid>
                    <Grid item xs={6}>
                      <SubTotalPrice style={{ fontWeight: 'bold' }}>
                        {priceUpdated ? subtotal : 'Price is Pending'}
                      </SubTotalPrice>
                    </Grid>
                  </GrandTotalBox>
                </CardWrapper>
              </Grid>
            </Grid>
          </CartGridContainer>
        )}
    </MainContent>
  );
};

export default QuoteDetails;
