import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Paper, Typography,
  Grid, TableContainer, Table, TableCell,
  TableRow
} from '@mui/material';

export const MainContent = styled('div')`
  margin-top: 4rem;
`;

export const CardWrapper = styled(Paper)`
  margin-bottom: 52px;
  border-radius: 15px;
  width: 100%;

  @media (min-width: 992px) {
    border-radius: 12px;
    box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
    padding: 1.5rem;
  }
  @media (min-width: 991px) {
    box-shadow: 0px 2.82416px 63.5818px rgba(163, 171, 185, 0.12);
  }
`;

export const PageTitleText = styled('p')`
  color: #303030;
  font-size: 3.6rem;
  font-weight: 650;
  margin: 0;
  @media (min-width: 992px) {
    font-size: 1.5rem;
    font-weight: 700;
    color: #303030;
    margin-bottom: .7rem;
  }
`;

export const PageTitleSubText = styled('p')`
  color: #606060;
  margin: 0;
  font-size: 2.1rem;
  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 1rem;
    padding-bottom: 40px;
    font-weight: 400;
  }
`;

export const SubTotalPrice = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 400;

  @media(min-width: 992px) {
    font-size: 1rem;
    font-weight: 400;
    color: #303030;
  }
`;

export const GrandTotalBox = styled(Grid)`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 2rem;
  width: 30%;
  padding: 20px 0px;
`;

export const GrandTotaltext = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;

  @media(min-width: 992px) {
    font-size: 1rem;
    color: #303030;
  }
`;

export const TableWrapper = styled(TableContainer)`
  padding: 15px 15px 0px 15px;
`;

export const TableComponent = styled(Table)`
  border-collapse: separate;
`;

export const Header = styled(TableCell)`
  padding: 25px 5px;
  font-weight: 700;
  font-size: 1rem;
  border-bottom: none !important;

  :first-child {
    width: 36%;
  }
  :last-child {
    width: 15%;
  }

  @media(min-width: 992px) {
    &:nth-child(2) {
      width: 17%;
    }
    &:nth-child(3) {
      width: 17%;
    }
    &:nth-child(4) {
      width: 15%;
    }
  }
`;

export const CartGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  background-color: #F7FBFF;
  @media (min-width: 992px) {
    padding: 1px 4rem 3rem 4rem;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;
  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const BackArrowIconContainer = styled('div')`
  padding: 2px 0px;
  margin: 0;
  background: none;
  color: #235A91;
  display: flex;
  width: 5rem;
  cursor: pointer;
`;

export const BackText = styled('p')`
  color: #235A91;
  font-size: .875rem;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 8px;
`;

export const PageTitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CartGridContainerHeader = styled(Grid)`
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-top: 3rem;
  }
`;

export const TableHeader = styled('thead')`
  background-color: #f3f9ff;
`;

export const MainTableRow = styled(TableRow)`
  border-bottom: 1px solid #ddd;
`;
