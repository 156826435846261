import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Hidden, Grid, Table, TableBody
} from '@mui/material';
import moment from 'moment';
import { ArrowBackIos } from '@mui/icons-material';
import toast from 'react-hot-toast';
import {
  PageTitleText, PageTitleSubText, GoBack,
  PageTitleContainer, CartGridContainerHeader
} from '../../../cart/cart.styles';
import CustomButton from '../../../customComponents/customButton';
import ReturnRow from './returnRow';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, GridButton, MenuButton, BackText,
  PaperContainer, SubtotalGrid, SubtotalText, FooterGrid,
  SaveContainer, Sync, CartGridContainer
} from './styles';
import { WH_SINGLE_ORDER_QUERY } from '../../../../queries/orders';
import MainContent from '../../../customComponents/mainContent';
import { reformatAddress } from '../../../../utils/json';
import DeliveryInfoCard from '../../../shared/deliveryInfoCard/deliveryInfoCard';
import OrderInfoCard from '../../../shared/orderInfoCard/orderInfoCard';
import TablePagination from '../../../shared/tablePagination';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import useSearchParamsState from '../../../shared/helpers/ulrSearchParams';
import currencyFormatter from '../../../../utils/currencyFormatter';
import {
  TableColumnHeaders, TableComponent, TableHeader
} from '../../../shared/tableGlobalStyle.styles';
import { CANCEL_RFQ_ORDER, PUSH_RFQ_ORDER_TO_ZERO, SAVE_RFQ_ORDER } from '../../../../mutations/orders';
import { handleDownloadUtil } from '../../../../utils/utils';
import { OgaToken } from '../../../../graphql/token';
import SuccessDialog from '../../../shared/successDialog';
import ConfirmDialog from '../../../shared/confirmDialog';

const headers = [
  'Product Items', 'Qty in Stock', 'Qty Ordered', 'Qty  Confirmed', 'Unit Cost', 'Cost Price',
];

const RfqOrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [confirmText, setConfirmText] = useState({ title: '', desc: '' });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState('');
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);

  const [saveRfqOrder, { loading: rfqloading }] = useMutation(SAVE_RFQ_ORDER);
  const [cancelRfqOrder] = useMutation(CANCEL_RFQ_ORDER);
  const [pushRfqOrderToZero] = useMutation(PUSH_RFQ_ORDER_TO_ZERO);

  const {
    loading, error, data, refetch
  } = useQuery(WH_SINGLE_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { orderId: id, pageCount, pageNumber }
  });

  useEffect(() => {
    if (data?.orderProducts?.length) {
      setProducts(data?.orderProducts);
    }
  }, [data]);

  if (error) return `Error! ${error.message}`;

  const {
    business, lastUpdated, meta, deliveryOutlet
  } = data?.singleOrder ?? {};

  const orderProducts = data?.orderProducts || {};
  const orderProductsTotalNumber = data?.orderProductsTotalNumber || 0;

  const businessContact = reformatAddress(deliveryOutlet?.contacts);
  const prods = orderProducts || [];
  const { rfq_status: rfqStatus } = reformatAddress(meta);

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

  let subtotal;
  if (data?.orderProducts) {
    subtotal = prods?.map((item) => {
      const { quantityConfirmed, unitCost } = item;
      const price = quantityConfirmed * unitCost;
      return price;
    }).reduce((sum, price) => sum + price, 0);
  }

  const handleInputChange = (pId, field, value) => {
    setProducts((prevProducts) => prevProducts.map((product) => (Number(product.id) === Number(pId) ? { ...product, [field]: value } : product)));
    setUpdatedProducts((prevUpdated) => {
      const existingProductIndex = prevUpdated.findIndex((product) => Number(product.rfqProductId) === Number(pId));

      if (existingProductIndex > -1) {
        const updatedProduct = {
          ...prevUpdated[existingProductIndex],
          [field]: value,
        };

        return prevUpdated.map((product, index) => (index === existingProductIndex ? updatedProduct : product));
      }

      return [...prevUpdated, { rfqProductId: pId, [field]: value }];
    });
  };

  const handleSave = () => {
    saveRfqOrder({
      variables: {
        rfqOrderId: id,
        updateData: updatedProducts,
      }
    })
      .then(() => {
        toast.success('Order successfully saved');
        refetch();
      }).catch((err) => {
        toast.error(err?.message);
      });
  };
  const handleCancelRfq = () => {
    setProcessLoading(true);
    cancelRfqOrder({
      variables: {
        rfqOrderId: id,
      }
    })
      .then(() => {
        refetch();
        toast.success('Order successfully cancelled');
        setSuccessOpenDialog(true);
        setConfirmText({
          title: 'Order Cancelled Successfully!',
          desc: ' Order has been successfully cancelled',
        });
        setOpenConfirmDialog(false);
        setProcessLoading(false);
      }).catch((err) => {
        toast.error(err?.message);
        setProcessLoading(false);
      });
  };

  const cancelRfq = () => {
    setConfirmStatus('Cancel');
    setConfirmText({
      title: 'Cancel Order?',
      desc: ' Are you sure you want to cancel this order?',
    });
    setOpenConfirmDialog(true);
  };

  const pushOrder = () => {
    setConfirmStatus('Push');
    setConfirmText({
      title: 'Push Order??',
      desc: ' Are you sure you want to push this order as available?',
    });
    setOpenConfirmDialog(true);
  };

  const handlePushRfqOrderToZero = () => {
    setProcessLoading(true);
    pushRfqOrderToZero({
      variables: {
        rfqOrderId: id,
      }
    })
      .then(() => {
        toast.success('Order successfully pushed to zero');
        setOpenConfirmDialog(false);
        setSuccessOpenDialog(true);
        setConfirmText({
          title: 'Order Pushed Sucessfully!',
          desc: ' Order has been successfully pushed as available!',
        });
        refetch();
        setProcessLoading(false);
      }).catch((err) => {
        toast.error(err?.message);
        setProcessLoading(false);
      });
  };
  const token = localStorage.getItem('oga_rest_token');

  const handleGenerateSupplierQuote = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_supplier_quote_csv', token, id)
        .then(() => {
          setConfirmText({
            title: 'Supplier Quote Generated',
            desc: ' Supplier’s quote has been generated  to confirm price',
          });
          setSuccessOpenDialog(true);
        });
    } catch (err) {
      toast.error('download failed');
    }
  };

  const handleGenerateProforma = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_proforma_with_product_qty_xlsx', token, id, 'rfq')
        .then(() => {
          setConfirmText({
            title: 'Proforma Generated',
            desc: 'A proforma has been generated  for confirmation',
          });
          setSuccessOpenDialog(true);
          refetch();
        });
    } catch (err) {
      toast.error('Proforma download failed');
    }
  };

  const handleCreatePO = () => navigate(`/update-purchase-order/${id}`, { state: { rfq: true } });

  return (
    <MainContent>
      <CartGridContainer>
        <GoBack container item alignItems="center">
          <MenuButton onClick={() => navigate(-1)}>
            <ArrowBackIos style={{ fontSize: '1.4rem' }} />
          </MenuButton>
          <BackText variant="caption">back</BackText>
        </GoBack>
        <Grid container item>
          <CartGridContainerHeader item container xs={12} md={5}>
            <PageTitleContainer>
              <PageTitleText>Order Request Detail</PageTitleText>
              <Hidden mdDown>
                <PageTitleSubText>View Details of an Order Request</PageTitleSubText>
              </Hidden>
            </PageTitleContainer>
          </CartGridContainerHeader>
          {rfqStatus === 'PENDING_PRICE_CONFIRMATION' || rfqStatus === 'PENDING_AFFILIATE_APPROVAL' || rfqStatus === 'PENDING_FINANCE_APPROVAL' ? (
            <GridButton xs={12} md={7}>
              {rfqStatus === 'PENDING_PRICE_CONFIRMATION' || rfqStatus === 'PENDING_AFFILIATE_APPROVAL' ? (
                <CustomButton
                  type="tertiary"
                  header
                  style={{ width: '9rem', height: '2.9rem' }}
                  onClick={cancelRfq}
                >
                  Cancel Order
                </CustomButton>
              ) : ''}

              <CustomButton
                type="tertiary"
                header
                style={{ width: '9rem', height: '2.9rem' }}
                onClick={handleGenerateSupplierQuote}
              >
                Supplier Quote
              </CustomButton>
              <CustomButton
                header
                disabled={loading}
                style={{ width: '11rem', height: '2.9rem' }}
                type={rfqStatus === 'PENDING_PRICE_CONFIRMATION' ? 'primary' : 'tertiary'}
                onClick={handleGenerateProforma}
              >
                Generate Proforma
              </CustomButton>
              {rfqStatus === 'PENDING_AFFILIATE_APPROVAL' && (
                <CustomButton
                  header
                  disabled={loading}
                  style={{ width: '10rem', height: '2.9rem' }}
                  onClick={pushOrder}
                >
                  Push Order
                </CustomButton>
              )}
            </GridButton>
          ) : ''}
          {rfqStatus === 'AWAITING_INBOUND' ? (
            <GridButton xs={12} md={7}>
              <CustomButton
                header
                style={{ width: '8rem', height: '2.9rem' }}
                onClick={handleCreatePO}
              >
                Create PO
              </CustomButton>
            </GridButton>
          ) : ''}
        </Grid>
        <Grid container spacing={4}>
          <SupplierDetailCardWrapper item container md={4}>
            <OrderInfoCard
              id={`ID${id}`}
              business={business}
              dateCreated={moment(lastUpdated).format('DD/MM/YYYY')}
              affiliateInfoCard
              rfq
            />
          </SupplierDetailCardWrapper>
          <SupplierDetailCardWrapper item container lg={8}>
            <SupplierDetailCard>
              <DeliveryInfoCard businessContact={businessContact} businessName={business?.name} status={rfqStatus} />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>
        <PaperContainer elevation={2}>
          <SaveContainer item container>
            <CustomButton
              type="tertiary"
              header
              onClick={handleSave}
              style={{ width: '10rem', height: '2.9rem' }}
              disabled={rfqloading}
            >
              <Sync fontSize="small" />
              Save Update
            </CustomButton>
          </SaveContainer>
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {loading ? <ProductsTableLoader /> : products?.map((prod) => <ReturnRow key={prod?.id} product={prod} handleInputChange={handleInputChange} rfqStatus={rfqStatus} />)}
              </TableBody>
            </Table>
          </TableComponent>
          <SubtotalGrid>
            <SubtotalText>
              <span style={{ marginRight: '1rem' }}>Subtotal: </span>
              {`${currencyFormatter(subtotal)}`}
            </SubtotalText>
          </SubtotalGrid>
          <FooterGrid item container>
            <TablePagination
              total={orderProductsTotalNumber}
              pageCount={+pageCount}
              setPageCount={setPageCount}
              pageNumber={+pageNumber}
              setPageNumber={setPageNumber}
            />
          </FooterGrid>
        </PaperContainer>
      </CartGridContainer>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title={confirmText?.title}
        desc={confirmText?.desc}
        options={['Cancel', 'Yes']}
        setStatus={setConfirmStatus}
        buttonAction={confirmStatus === 'Cancel' ? handleCancelRfq : handlePushRfqOrderToZero}
        loading={processLoading}
      />
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={confirmText?.title}
        desc={confirmText?.desc}
        option="ok"
        refetch={refetch}
      />
    </MainContent>
  );
};

export default RfqOrderDetails;
