import PropTypes from 'prop-types';
import React from 'react';
import { InputAdornment } from '@mui/material';
import { JSONParse } from '../../../../utils/json';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CustomInputNumberBase, ProductDetail, ProductDetailBrandNameText, ProductDetailImage,
  ProductDetailNameMainContainer, ProductDetailPackSizeText, BodyCell
} from './styles';
import { TableColumnRows } from '../../../shared/tableGlobalStyle.styles';

const ReturnRow = ({ product, handleInputChange, rfqStatus }) => {
  const {
    unitCost, name: brandName, product: { meta }, quantity, quantityConfirmed, quantityInStock
  } = product;
  const prodMeta = JSONParse(meta.replace(/'/g, '"'));

  const totalPrice = quantityConfirmed * unitCost;
  return (
    <TableColumnRows>
      <BodyCell>
        <ProductDetail>
          <ProductDetailImage src={prodMeta?.image} alt="" />
          <ProductDetailNameMainContainer>
            <ProductDetailBrandNameText>
              {brandName}
            </ProductDetailBrandNameText>
            <ProductDetailPackSizeText>
              {`Pack Size: ${prodMeta?.pack_size ?? 'N/A'}`}
            </ProductDetailPackSizeText>
          </ProductDetailNameMainContainer>
        </ProductDetail>
      </BodyCell>
      <BodyCell>{Number(quantityInStock)}</BodyCell>
      <BodyCell>{Number(quantity)}</BodyCell>
      <BodyCell>
        {rfqStatus === 'PENDING_PRICE_CONFIRMATION' || rfqStatus === 'PENDING_AFFILIATE_APPROVAL' ? (
          <CustomInputNumberBase
            type="number"
            placeholder="0"
            min={1}
            size="medium"
            required
            value={quantityConfirmed}
            onChange={(e) => handleInputChange(product?.id, 'quantityConfirmed', +e.target.value)}
            style={{ width: '100px' }}
          />
        ) : quantityConfirmed }
      </BodyCell>
      <BodyCell>
        {rfqStatus === 'PENDING_PRICE_CONFIRMATION' ? (
          <CustomInputNumberBase
            type="number"
            placeholder="0"
            min={1}
            size="medium"
            required
            value={unitCost}
            onChange={(e) => handleInputChange(product?.id, 'unitCost', +e.target.value)}
            startAdornment={(
              <InputAdornment position="start" style={{ paddingLeft: '10px' }}>
                ₦
              </InputAdornment>
            )}
            style={{ width: '100px' }}
          />
        ) : `₦ ${currencyFormatter(unitCost)}` }
      </BodyCell>
      <BodyCell>
        {`₦ ${currencyFormatter(totalPrice || 0)}`}
      </BodyCell>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  rfqStatus: PropTypes.string.isRequired,
};

export default ReturnRow;
