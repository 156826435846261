import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button
} from '@mui/material';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import SearchPopper from './searchPopper';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack
} from './purchaseOrder.styles';
import { GET_PO_ORDERS, SEARCH_PO_QUERY } from '../../../queries/products';
import ReturnRow from './returnRow';
import { BodyCell } from './returnRow.styles';
import TablePagination from '../../shared/tablePagination';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { PLACE_PO_ORDER_PRODUCTS_MUTATION, DELETE_CART_PRODUCT } from '../../../mutations/cart';
import { generateRandomNumber } from '../../../utils/funcs';
import { CustomCheckbox } from '../mangePurchaseOrder/returnRow.styles';

const poHeaders = [
  'S/N',
  'Product ID',
  'Product Name',
  'Supplier',
  'Pack Size',
  'Qty',
  'PO Unit Cost',
  'Price',
  'Action'
];

function UpdatePurchaseOrderContainer() {
  const returnHeaders = () => poHeaders.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const location = useLocation();

  const localBusinessId = localStorage.getItem('ogarx_business_id');

  const { id } = useParams();

  const isRFQ = location?.state?.rfq;

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selected, setSelected] = useState([]);
  const [fRefetch, setFRefetch] = useState(false);
  const [reload, setReload] = useState(false);
  // trigger to disable or hide buttons when an update is made on so as to prevent loss of data
  const [trigger, setTrigger] = useState(false);
  const [products, setProducts] = useState([]);
  const reloadHandler = () => setReload(!reload);

  const { data, loading: poloader, refetch } = useQuery(GET_PO_ORDERS, {
    variables: { orderId: id, pageCount, pageNumber },
    fetchPolicy: 'network-only'
  });

  const prod = data?.poOrderProducts || [];
  const count = data?.poOrderProductsCount;

  useEffect(() => {
    const result = prod.map((item) => {
      const {
        id: orderProductId, quantity, unitCost, product, supplierOrder
      } = item;
      const { pack_size: packSize } = JSON.parse(product.meta);
      return {
        sn: generateRandomNumber(10),
        productId: product?.id,
        productName: product?.brandName,
        supplier: supplierOrder?.supplier?.name,
        packSize,
        quantity,
        newUnitCost: unitCost,
        price: quantity * unitCost,
        orderProductId
      };
    });
    setProducts(result);
  }, [data, fRefetch]);

  const counterHandler = (type, row, val) => {
    setTrigger(true);
    const { sn, quantity, newUnitCost } = row;
    switch (type) {
      case 'increment': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) + 1;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;
        break;
      }
      case 'decrement': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) - 1;
        if (newQuantity < 0) return;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;
        break;
      }
      case 'change': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        if (val <= 0) return;
        newData.quantity = Number(val);
        newData.price = val * newUnitCost;
        break;
      }
      default:
        break;
    }
    reloadHandler();
  };

  const { data: businessData } = useQuery(GET_MY_BUSINESS, {
    variables: { id: localBusinessId }
  });
  const deliveryLocationId = businessData?.business?.deliveryLocations[0]?.id || 0;

  const otherRequest = {
    ...(!isRFQ && { orderId: id }),
    isManualOrder: true,
    deliveryLocationId: Number(deliveryLocationId),
    creditScoreBusiness: localBusinessId,
    extraActions: [],
    extraProductsMeta: [],
    affectedSuppliers: [],
    idempotentKey: String(uuid())
  };

  const [search, setSearch] = useState('');
  const [pId, setPId] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [currentRowSearch, setCurrentRowSearch] = useState(0);

  const [getSearchedProducts, { data: poData, loading }] = useLazyQuery(
    SEARCH_PO_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        productId: pId,
        status: 'enabled'
      }
    }
  );

  const createData = (product) => {
    const createProd = new Product(product, 'erp');
    return {
      productId: createProd?.id,
      brandName: createProd?.brandName,
      quantityInStock: createProd?.quantityInStock,
      supplier: createProd?.supplier,
      packSize: createProd?.packSize,
      itemPrice: createProd?.orderCost
    };
  };

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  useEffect(() => {
    if (poData && poData.erpProducts) {
      const sResults = poData.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
  }, [poData]);

  const handleSearch = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set search field
    setSearch(value);
    setPId('');
    // Update the product name for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productName = value;
    setProducts([...products]);

    if (value && value.length > 2) {
      setSavedEl(event.target);
      getSearchedProducts();
    } else {
      handlePopperClose();
      setSavedEl(null);
    }
  };

  const handleSearchByPId = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set product id search field
    setSearch('');
    setPId(value);
    // Update the product id for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productId = value;
    setProducts([...products]);

    if (value) {
      setSavedEl(event.target);
      getSearchedProducts();
    } else {
      handlePopperClose();
      setSavedEl(null);
    }
  };

  const addMoreHandler = () => {
    const next = generateRandomNumber(10);
    const newData = {
      sn: next,
      productId: '',
      productName: '',
      supplier: '',
      packSize: '',
      quantity: 0,
      newUnitCost: 0,
      price: 0
    };
    setProducts([...products, newData]);
  };

  const [placePoOrder, { loading: poLoading }] = useMutation(
    PLACE_PO_ORDER_PRODUCTS_MUTATION
  );

  const [deleteProduct, { loading: delLoading }] = useMutation(
    DELETE_CART_PRODUCT
  );

  const handleProductSelection = (row) => {
    const {
      productId, brandName, supplier, itemPrice, packSize
    } = row;

    const newProduct = products.find(
      ({ productName, productId: pid }) => productName === brandName && pid !== ''
    );

    if (newProduct) return toast.error('Product already exists');

    const newData = products.find(({ sn }) => sn === currentRowSearch);
    newData.productId = productId;
    newData.productName = brandName;
    newData.supplier = supplier.name;
    newData.packSize = packSize;
    newData.quantity = 1;
    newData.newUnitCost = itemPrice;
    newData.price = itemPrice;
    setProducts([...products]);
    setTrigger(true);
    handlePopperClose();
  };

  useEffect(() => {
    setProducts([...products]);
  }, [reload]);

  const updateCostPriceHandler = (val, row) => {
    const { sn, quantity } = row;
    const newData = products.find(({ sn: sid }) => sn === sid);
    newData.newUnitCost = val;
    newData.price = quantity * val;

    setProducts([...products]);
    setTrigger(true);
    reloadHandler();
  };

  // ================= Save Draft Handler ===================
  const saveDraftHandler = async () => {
    const filterItems = products.filter((item) => item.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((item) => {
      const { productId, quantity, newUnitCost } = item;
      return {
        productId,
        quantity,
        newUnitCost
      };
    });

    if (!cart.length) return toast.error('Purchase order is empty');
    const request = {
      ...otherRequest, cart, isADraft: true, ...(isRFQ && { rfqOrderId: id })
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        toast.success('Purchase order successfully saved as draft');
        refetch();
        setTrigger(false);
        // navigate('/manage-purchase-order');
      })
      .catch(() => {});
  };

  const removeRowHandler = (sn) => {
    const {
      productId, productName, quantity, orderProductId
    } = products.find(({ sn: sid }) => sn === sid);

    if (productId === '' || productName === '' || quantity === 0) {
      const newProducts = products.filter((p) => p.sn !== sn);
      setProducts(newProducts);
    } else {
      const variables = {
        orderId: id,
        orderProductIds: [orderProductId]
      };

      deleteProduct({
        variables
      })
        .then(() => {
          toast.success('Purchase order successfully deleted');
          const newProducts = products.filter((p) => p.sn !== sn);
          setProducts(newProducts);
          refetch();
        })
        .catch(() => {});
    }

    setTrigger(true);
  };

  // ================= Save PO Handler ===================
  const createPOHandler = () => {
    // Remove row that does not have product id
    const filterItems = products.filter((item) => item.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((item) => {
      const { productId, quantity, newUnitCost } = item;
      return {
        productId: Number(productId),
        quantity,
        newUnitCost
      };
    });

    if (cart.length < 1) return toast.error('Purchase order is empty');

    const request = {
      ...otherRequest, cart, isADraft: false, ...(isRFQ && { rfqOrderId: id })
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        toast.success('Purchase order successfully create');
        navigate('/manage-purchase-order');
      })
      .catch(() => {});
  };

  const deleteHandler = () => {
    if (selected.length < 1) return toast.error('Kindly select an item to delete');
    products.map((delProd) => {
      selected.map((item) => {
        if (item === delProd.sn) {
          delProd.quantity = 0;
          delProd.newUnitCost = 0;
        }
      });
    });

    const filterItems = products.filter((item) => item.productId !== '');

    // Select only the required items from the filtered list
    const selectedProd = filterItems
      .filter((product) => selected.includes(product.sn))
      .map((item) => item.orderProductId);

    const variables = {
      orderId: id,
      orderProductIds: selectedProd
    };

    if (selectedProd.length < 1) return toast.error('Purchase order is empty');

    deleteProduct({
      variables
    })
      .then(() => {
        toast.success('Item(s) deleted successfully');
        refetch();
        setFRefetch(!fRefetch);
        setSelected([]);
      })
      .catch(() => {});
  };

  const handleSelect = (_, sn) => {
    if (_.target.checked) {
      const list = selected.filter((item) => item !== sn);
      setSelected([...list, sn]);
    } else {
      const list = selected.filter((item) => item !== sn);
      setSelected(list);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = products.filter((product) => {
        if (product.sn) return product.sn;
      }).map((product) => product.sn);
      setSelected(newSelections);
    } else {
      setSelected([]);
    }
  };
  const checked = products.length === selected.length;
  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={checked}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  let rowCounter = 0;
  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={() => navigate('/manage-purchase-order')}>
          <KeyboardArrowLeft
            style={{ fontSize: '1.8rem', cursor: 'pointer' }}
          />
          back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Create Purchase Order</PrimaryTitle>
            <ButtonContainer>
              <Button
                variant="outlined"
                disabled={delLoading || isRFQ}
                style={{ width: '11rem', height: '3rem', border: '2px solid' }}
                onClick={deleteHandler}
              >
                Delete Item(
                {selected.length}
                )
              </Button>
              <Button
                variant="outlined"
                disabled={poLoading || isRFQ}
                style={{ width: '8rem', height: '3rem', border: '2px solid' }}
                onClick={saveDraftHandler}
              >
                Save Draft
              </Button>
              <Button
                variant="contained"
                disabled={poLoading}
                style={{ width: '8rem', height: '3rem' }}
                onClick={createPOHandler}
              >
                Create PO
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  <TableHeader>
                    {renderCheckbox()}
                  </TableHeader>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <TableBody>
                  {poloader ? (
                    <ProductsTableLoader />
                  ) : !products?.length ? (
                    <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                      Purchase order is empty
                    </BodyCell>
                  ) : (
                    products?.map((item) => {
                      const { sn } = item;
                      rowCounter += 1;
                      return (
                        <ReturnRow
                          key={sn}
                          row={item}
                          index={rowCounter}
                          loading={loading}
                          counterHandler={counterHandler}
                          handleSearch={handleSearch}
                          removeRowHandler={removeRowHandler}
                          handleSelect={handleSelect}
                          updateCostPriceHandler={updateCostPriceHandler}
                          handleSearchByPId={handleSearchByPId}
                          poLoading={poLoading}
                          currentRowSearch={currentRowSearch}
                          selected={selected}
                          isRFQ={isRFQ}
                        />
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>

          <Button
            variant="outlined"
            onClick={addMoreHandler}
            disabled={isRFQ}
            style={{
              width: '11rem',
              height: '3rem',
              border: '2px solid',
              marginTop: '1rem',
              marginBottom: '1.5rem'
            }}
          >
            Add New Product
          </Button>
          {trigger ? null : (
            <TablePagination
              total={count}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </PaperWrapper>
        <SearchPopper
          searchFieldEl={searchFieldEl}
          handleClose={handlePopperClose}
          searchedProducts={searchedProducts}
          handleProductSelection={handleProductSelection}
        />
      </GridContainer>
    </MainContent>
  );
}

export default UpdatePurchaseOrderContainer;
