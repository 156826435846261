import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabsGrid = styled(Grid)`
  padding: .5rem 0px 1rem;
  align-items: center;
  @media (max-width: 991px) {
    padding-bottom: 3rem;
  }
`;

export const TCustomButton = styled(Button)`
  font-weight: 500;
  font-size: 0.875rem;
  background-color: none;
  margin-right: .7rem;
  border: ${({ active }) => (active ? '1px solid #235A91' : '1px solid #D7D7D7')};
  border-radius: 8px;
  padding: 5px 15px;
  color: ${({ active }) => (active ? '#235A91' : '#A7A8A8')};

  @media (max-width: 991px) {
    font-size: 2rem;
    margin-top: 15px;
    margin-right: .5rem;
    padding: 10px 30px;
    border-radius: 20px;


  }

`;
