import React from 'react';
import PropTypes from 'prop-types';
import {
  ProductDetailMainContainer, ImageWrapper, ProductDetailImage, ProductCounter,
  ProductDetailBrandNameText, ProductDetailNameMainContainer, MainTableRow, BodyCell,
  TableValueText, ProductDetailFlex, PriceBox, PriceBullet,
  PriceTypo,
} from './returnRow.styles';

const ReturnRow = ({ product }) => {
  const {
    name, quantity, marketplaceVersion, unitCost, price
  } = product;
  const { image, packSize } = marketplaceVersion;

  return (
    <MainTableRow>
      <BodyCell container>
        <ProductDetailFlex>
          <ImageWrapper>
            <ProductDetailImage src={image} alt="product" />
          </ImageWrapper>
          <ProductDetailNameMainContainer>
            <PriceBox>
              <PriceBullet />
              <PriceTypo>
                Price Can Change
              </PriceTypo>
            </PriceBox>
            <ProductDetailBrandNameText>
              { name }
            </ProductDetailBrandNameText>
          </ProductDetailNameMainContainer>
        </ProductDetailFlex>
      </BodyCell>
      <BodyCell>
        <ProductCounter>
          <TableValueText>
            {packSize}
          </TableValueText>
        </ProductCounter>
      </BodyCell>
      <BodyCell>
        <ProductCounter>
          <TableValueText>
            {Number(quantity)}
          </TableValueText>
        </ProductCounter>
      </BodyCell>
      <BodyCell>
        <ProductDetailMainContainer>
          <TableValueText>
            {unitCost}
          </TableValueText>
        </ProductDetailMainContainer>
      </BodyCell>
      <BodyCell>
        <TableValueText>
          {price}
        </TableValueText>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ReturnRow.defaultProps = {
};

export default ReturnRow;
