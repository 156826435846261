export const addItemToCart = (cartItems, payload) => {
  const { product, counter } = payload;
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === product.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) => (cartItem.id === existingCartItem.id
      ? { ...cartItem, quantity: cartItem.quantity + counter }
      : cartItem));
  }

  return [...cartItems, { ...product, quantity: counter }];
};

export const updateCartCounter = (cartTot) => cartTot;

export const updateCartCount = (total) => total;

export const updateCartItems = (cartItems, payload) => {
  const { id, type } = payload;
  let updatedCartItems;
  if (type === 'add') {
    updatedCartItems = cartItems.includes(id)
      ? cartItems
      : [...cartItems, id];
  } else if (type === 'remove') {
    updatedCartItems = cartItems.filter((itemId) => itemId !== id);
  } else if (type === 'clear') {
    updatedCartItems = [];
  }
  return updatedCartItems;
};
