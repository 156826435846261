import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  OrderDetailCard, OrderDetailText, OrderDetailWrapper, OrderDetailLogo,
  OrderDetailInfoWrapper, OrderFulfilledWrapper, OrderFulfilledInfoName,
  SupplierPaymentTermText, OrderDetailSupplierInfoLogo, OrderDetailSupplierInfoName, OrderDetailBoldText, AffiliateBoldText
} from './orderInfoCard.styles';

const OrderInfoCard = ({
  id, business, dateCreated, businessDateDelivered, fulfilledBy, supplierPaymentTerm, supplier, displayId,
  returns, orderIsReturned, returnOrderId, affiliateInfoCard, rfq
}) => {
  const navigate = useNavigate();
  return (
    <OrderDetailCard elevation={returns && 2} returns={returns}>
      {/* <OrderDetailCardHeader>
        <p
          style={{
            color: '#424242',
            fontSize: '20px',
            fontWeight: '600',
            margin: 0
          }}
        />
        <OrderDetailIdText>{id}</OrderDetailIdText>
      </OrderDetailCardHeader> */}

      <OrderDetailWrapper container>
        <OrderDetailLogo sm={6} src={business && business.logo} />
        {/* <OrderDetailName>{ business && business.name }</OrderDetailName> */}
      </OrderDetailWrapper>

      <OrderDetailInfoWrapper>
        { supplierPaymentTerm ? <SupplierPaymentTermText>{ supplierPaymentTerm }</SupplierPaymentTermText> : '' }
        {/* <OrderDetailText sm={6}>{id}</OrderDetailText> */}
        {affiliateInfoCard ? (
          <>
            <OrderDetailText>
              Placed on &nbsp; &nbsp;
              <AffiliateBoldText>
                {`: ${dateCreated}`}
              </AffiliateBoldText>
            </OrderDetailText>
            {!rfq ? (
              <OrderDetailText>
                Estimated Delivery &nbsp; &nbsp;
                <AffiliateBoldText>{ businessDateDelivered ? ` : ${businessDateDelivered}` : ': N/A' }</AffiliateBoldText>
              </OrderDetailText>
            ) : ''}
            {fulfilledBy
              ? (
                <OrderFulfilledWrapper>
                  <OrderFulfilledInfoName>
                    {`Fulfilled by ${fulfilledBy}`}
                  </OrderFulfilledInfoName>
                </OrderFulfilledWrapper>
              ) : ''}
          </>
        ) : (
          <Grid container>
            <Grid item md={returns ? 4 : 6}>
              {displayId !== 'hide' ? <OrderDetailText returns={returns}>Order Number:</OrderDetailText> : ''}
              {orderIsReturned && returnOrderId
                ? (
                  <OrderDetailText style={{ cursor: 'pointer' }} onClick={() => navigate(`/orders-admin/dispatch/${returnOrderId}`)}>
                    Parent Order ID:
                  </OrderDetailText>
                )
                : ''}
              <OrderDetailText returns={returns}>{displayId !== 'hide' ? 'Last Updated:' : 'Placed on:'}</OrderDetailText>
              <OrderDetailText returns={returns}>Estimated Delivery:</OrderDetailText>
              <OrderDetailText returns={returns}>
                {fulfilledBy && 'Fulfilled by:'}
              </OrderDetailText>
            </Grid>
            <Grid item md={returns ? 8 : 6}>
              {displayId !== 'hide' ? <OrderDetailBoldText returns={returns}>{id}</OrderDetailBoldText> : ''}
              {orderIsReturned && returnOrderId
                ? (
                  <OrderDetailBoldText style={{ cursor: 'pointer' }} onClick={() => navigate(`/orders-admin/dispatch/${returnOrderId}`)} returns={returns}>
                    {`00${returnOrderId}`}
                  </OrderDetailBoldText>
                )
                : ''}
              <OrderDetailBoldText returns={returns}>{dateCreated}</OrderDetailBoldText>
              <OrderDetailBoldText returns={returns}>
                { businessDateDelivered ? `${businessDateDelivered}` : ': N/A' }
              </OrderDetailBoldText>
              <OrderDetailBoldText returns={returns}>
                {fulfilledBy && `${fulfilledBy}`}
              </OrderDetailBoldText>
            </Grid>
          </Grid>
        )}
        {supplier
          ? (
            <OrderFulfilledWrapper>
              <OrderDetailSupplierInfoLogo src={supplier && supplier.logo} />
              <OrderDetailSupplierInfoName>{ supplier && supplier.name }</OrderDetailSupplierInfoName>
            </OrderFulfilledWrapper>
          ) : ''}
      </OrderDetailInfoWrapper>
    </OrderDetailCard>
  );
};

OrderInfoCard.propTypes = {
  business: PropTypes.instanceOf(Array),
  id: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  businessDateDelivered: PropTypes.string.isRequired,
  fulfilledBy: PropTypes.string,
  supplierPaymentTerm: PropTypes.string,
  supplier: PropTypes.instanceOf(Array).isRequired,
  returns: PropTypes.bool,
  orderIsReturned: PropTypes.bool,
  returnOrderId: PropTypes.string,
  affiliateInfoCard: PropTypes.string,
  rfq: PropTypes.string,
};

OrderInfoCard.defaultProps = {
  business: [],
  fulfilledBy: '',
  supplierPaymentTerm: '',
  returns: false,
  orderIsReturned: false,
  returnOrderId: '',
  affiliateInfoCard: '',
  rfq: ''
};

export default OrderInfoCard;
