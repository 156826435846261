import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const sm = '960px';

export const SupplierDetailCardHeader = styled('div')`
padding-top: 3rem;
padding-bottom: 15px;
border-bottom: 1px solid #E8E8E8;
display: flex;
justify-content: space-between;

@media (max-width: 991px) {
  padding-top: 10px;
  padding-bottom: 20px;
}
`;

export const SupplierDetailCardTitle = styled('p')`
  color: #303030;
  font-weight: 700;
  font-size: 16px;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const OrderDeliveryDetailInfoWrapperRow = styled('div')`
  margin-top: 20px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: ${sm}) {
    display: flex;

    align-items: center;
    justify-content: space-between;

    & > * {
      margin-bottom: unset;
    }
  }
`;

export const OrderDeliveryInfoLabel = styled('p')`
  font-weight: 500;
  font-size: 14px;
  color: #606060;
  margin: 0 0 1rem 0;

  @media (max-width: 991px) {
    font-size: 2rem;
    margin: 0 0 1.5rem 0;
  }
`;

export const OrderDeliveryInfoText = styled('p')`
  margin: 0;
  font-weight: 700;
  font-size: 2.3rem;
  color: #606060;
  margin: 0 0 1.5rem 0;

  @media (min-width: 992px) {
    font-size: 17px;
    margin: 0;
  }
`;

export const DeliveryStatusText = styled('p')`
  color: #606060;
  font-size: 27px;
  margin: 0;
  border: .6px solid #E4E4E4;
  padding: 7px 10px;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  align-items: center;

  @media (min-width:992px) {
    font-size: 16px;
  }
`;

export const OrderBusinessName = styled('p')`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 22px;
    margin-bottom: 15px;
  }
`;

export const StatusColor = styled(Box)`
  width: 0.563rem;
  height: 0.563rem;
  background: ${({ bkg }) => (bkg)};
  border-radius: 50%;
`;

export const StatusText = styled(Typography)`
  text-transform: capitalize;
`;
