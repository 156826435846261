import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckIcon } from '../../../assets/svgs';

export const Container = styled(Grid)`
  display: flex;
  min-width: 35rem;
  background-color: #ECF6FF;
  box-shadow: 4px 4px 6.6px 0px #FFFFFF40;
  justify-content: space-between;
  padding: 20px;
  border-radius: 6px;
  margin-top: 60px;
  align-items: center;

  @media(max-width: 991px) {
    margin-top: 10px;
    padding: 35px;
    min-width: 50rem;
  }

`;

export const TestWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const ViewItem = styled(Grid)`
  color: #235A91;
  font-size: 14px;
  font-weight: 700;
  line-height: 22.82px;
  cursor: pointer;

  @media(max-width: 991px) {
      font-size: 2rem;
  }
`;

export const Info = styled(Grid)`
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.56px;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const CheckImg = styled(CheckIcon)`
&.MuiSvgIcon-root {
  width: 1.4rem;
  height: 1.4rem;
  margin: 0 1rem;

  @media(max-width: 991px) {
    width: 3rem;
    height: 3rem;
  }
}
`;
