import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { GET_OPEN_AND_CLOSED_ORDERS, GET_RFQ_ORDERS } from '../../../queries/orders';

import { useStateValue } from '../../../providers/stateProvider';
import Orders from './orders/orders';
import MainContent from '../../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, PrimaryTitle, OrderWrapper
} from './ordersContainer.styles';
import TabsButtons from './ordersTabsButtons/tabsButtons';
import { ButtonsGrid } from './orders/ordersFilter.styles';
import CustomButton from '../../customComponents/customButton';
import { AffiliateModal } from './affiliate-modal';
import OrderDropdown from '../../shared/orderDropdown/orderDropdown';
import { addHyphen } from '../../shared/methods';
import SearchPopper from './orders/searchPopper';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const OrdersAdminContainer = () => {
  const {
    status: pStatus, orderNo: pOrderNo, metaStatus: pMetaStatus, businessCategory: pBusinessCategory,
    dateFrom: pDateFrom, dateTo: pDateTo, businessName: pBusinessName,
  } = useParams();
  const [{ order: { ordersStatus } }, dispatch] = Object.values(useStateValue());
  const defaultStatus = ordersStatus === '' ? 'open' : ordersStatus;
  const isSmall = useMediaQuery('(max-width: 991px)');
  const initialState = {
    status: pStatus ?? defaultStatus,
    currentPage: 1,
    currentPageCount: '',
    statusValue: 0,
    orderNo: '',
    businessName: '',
    dateFrom: '',
    dateTo: '',
    orderTotalFrom: '',
    orderTotalTo: '',
    productName: '',
    orderStatus: '',
    filters: { status: pStatus ?? defaultStatus },
    approvalStatus: '',
    businessCategory: ''
  };
  const [state, setState] = useState(initialState);
  const {
    status, currentPage, currentPageCount, orderNo, businessName, dateFrom, dateTo,
    orderTotalFrom, orderTotalTo, productName, orderStatus, approvalStatus, businessCategory
  } = state;

  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const [active, setActive] = useState(status);
  const [filters, setFilters] = useSearchParamsState('filters', status);

  const [action, setAction] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orderButtonEl, setOrderButtonEl] = useState(null);
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [searchedCategory, setSearchedCategory] = useState(null);
  const role = localStorage.getItem('oga_user_role');
  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';
  const vals = filters.split(',');

  const navigate = useNavigate();

  useEffect(() => {
    const initialFils = {
      status: pStatus || '', orderNo: pOrderNo || '', metaStatus: pMetaStatus || '',
      businessCategory: pBusinessCategory || '', dateFrom: pDateFrom || '', dateTo: pDateTo || '',
      businessName: pBusinessName || ''
    };
    const fils = {
      ...state, ...initialFils,
      filters: initialFils
    };
    setState(fils);
    setActive(pStatus);
  }, [pOrderNo, pBusinessName, pStatus]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      status: vals[0] || '',
      orderNo: (vals[1] === '0' ? '' : vals[1]) || '',
      metaStatus: vals[2] || '',
      businessCategory: vals[3] || '',
      dateFrom: vals[4] || '',
      dateTo: vals[5] || '',
      businessName: vals[6] || '',
      orderStatus: vals[2] || ''
    }));
    setActive(vals[0]);
  }, [filters]);

  useLayoutEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleOrderButtonClick = () => {
    setAction(true);
    navigate('/new-order');
  };

  const handlePopperClose = () => {
    setSearchFieldEl(null);
  };

  const handleCategorySelection = (value) => {
    setState((_state) => ({
      ..._state,
      businessCategory: value
    }));
    handlePopperClose();
  };

  const handleSetFilter = () => {
    const values = `${status},${+orderNo},${orderStatus === 'All' ? '' : orderStatus},${businessCategory},${dateFrom},${dateTo},${businessName}`;
    setFilters(values);
    setState({
      ...state,
      filters: {
        orderNo: Number(orderNo), status, metaStatus: orderStatus === 'All' ? '' : orderStatus, dateFrom, dateTo, businessName,
        orderTotalFrom, orderTotalTo, productName, businessCategory
      }
    });
  };

  const handleSortOrder = () => {
    setState({
      ...state, approvalStatus: (approvalStatus && approvalStatus === 'True') ? 'False' : (approvalStatus && approvalStatus === 'False') ? 'True' : 'False',
      filters: {
        approvalStatus: (approvalStatus && approvalStatus === 'True') ? 'False' : (approvalStatus && approvalStatus === 'False') ? 'True' : 'False',
      }
    });
  };
  const handleClearFilter = () => {
    const values = `${status}`;
    setFilters(values);
    setState(initialState);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const handleDateSubmit = (dateRange) => {
    const { dateFrom: newDateFrom, dateTo: newDateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(newDateFrom).format('YYYY-M-DD'),
      dateTo: moment(newDateTo).format('YYYY-M-DD'),
    }));
  };
  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleStatusValueChange = (newValue) => {
    setActive(newValue);
    const values = `${newValue}`;
    setFilters(values);
    setState({
      ...state,
      filters: { status: newValue, metaStatus: '' },
      status: newValue,
      orderStatus: ''
    });
  };

  const variables = {
    pageCount,
    pageNumber,
    status: vals[0],
    metaStatus: vals[2],
    businessCategory: vals[3],
    dateFrom: vals[4],
    dateTo: vals[5],
    businessName: vals[6],
    ...((vals[1] !== '0') && { orderNo: vals[1] })
  };

  const rfqVariables = {
    pageCount,
    pageNumber,
    forAffiliate: false,
    dateFrom: vals[4],
    dateTo: vals[5],
    status: vals[2],
    ...((vals[1] !== '0') && { id: vals[1] })
  };

  const {
    data, loading, error, refetch
  } = useQuery(GET_OPEN_AND_CLOSED_ORDERS, {
    fetchPolicy: 'no-cache',
    skip: status === 'quoteReq',
    variables
  });

  const {
    data: rfqData, loading: rfqLoading, error: rfqError, refetch: rfqRefech
  } = useQuery(GET_RFQ_ORDERS, {
    fetchPolicy: 'no-cache',
    skip: status !== 'quoteReq',
    variables: rfqVariables
  });

  if (error || rfqError) return `Error! ${error.message}`;

  const refetchData = status === 'quoteReq' ? rfqRefech : refetch;

  const rfqOrders = rfqData?.rfqOrders;
  const rfqOrderTotalNumber = rfqData?.rfqOrdersTotalNumber || 0;

  const {
    businessOrderTotalNumber = 0
  } = data ?? '';

  const totalCount = status === 'quoteReq' ? rfqOrderTotalNumber : businessOrderTotalNumber;

  const handleSelectAffiliate = () => {
    setOpenModal(true);
  };
  const handleDispatchOrders = () => navigate('/dispatch-orders');
  const buttons = [
    {
      name: 'Dispatch Orders',
      type: 'tertiary',
      handleClick: handleDispatchOrders,
      styles: {
        width: '11rem', height: '2.7rem',
      },
      disabled: !userPermissions?.includes('manage_orders_dispatch_order')
    },
    {
      name: '+ Create new order',
      handleClick: handleSelectAffiliate,
      styles: { width: '12rem', height: '2.7rem' },
      disabled: !userPermissions?.includes('manage_orders_create_new_order')
    },
  ];

  return (
    <MainContent>
      <OrderGridContainer container>
        {!isSmall && (
          <TitleGridContainer container item>
            <PrimaryTitle xs={6}> Manage Orders</PrimaryTitle>
            <ButtonsGrid xs={6} container item justifyContent="flex-end">
              {buttons.map(({
                name, type, styles, handleClick, disabled
              }) => (
                <CustomButton
                  key={name}
                  type={type}
                  style={{ ...styles, marginLeft: '.5rem' }}
                  onClick={handleClick}
                  disabled={disabled}
                  className={`mpAdmin-uat-ordersPage-${addHyphen(name)}`}
                >
                  {name}
                </CustomButton>
              ))}
            </ButtonsGrid>
          </TitleGridContainer>
        )}
        <OrderWrapper item container xs={12}>
          {!isSmall && (
          <Grid item container xs={12}>
            <TabsButtons
              active={active}
              setActive={setActive}
              handleStatusValueChange={handleStatusValueChange}
            />
          </Grid>
          )}
          <Orders
            state={state}
            data={data}
            error={error}
            loading={loading || rfqLoading}
            handleSetFilter={handleSetFilter}
            handleFilterChange={handleFilterChange}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={totalCount}
            page={+currentPage}
            currentPageCount={+currentPageCount}
            viewStatus={status}
            handleClear={handleClearFilter}
            handleDateSubmit={handleDateSubmit}
            refetch={refetchData}
            handleSort={handleSortOrder}
            pageCount={+pageCount}
            pageNumber={+pageNumber}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
            rfqOrders={rfqOrders}
          />
        </OrderWrapper>
      </OrderGridContainer>
      <AffiliateModal
        afterMath={handleOrderButtonClick}
        open={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedCategory={searchedCategory}
        handleCategorySelection={handleCategorySelection}
      />
      <OrderDropdown
        route="/new-order"
        extraAction={action}
        isAdmin={isAdmin}
        orderButtonEl={orderButtonEl}
        setOrderButtonEl={setOrderButtonEl}
        handleProductButtonClick={handleOrderButtonClick}
      />
    </MainContent>
  );
};

export default OrdersAdminContainer;
