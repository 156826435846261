import {
  Grid, Box, TableCell, Paper, TableRow,
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableRow)`
  width: 100%;
   & > * {
    border-bottom: none !important;
  }
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const ErpHeader = styled(TableCell)`
  /**text-transform: uppercase;**/
  padding: 15px 2px;
  background: #F3F9FF;
  color: #303030;
  font-weight: 700;
  font-size: .95rem;
  & > * {
    border-bottom: none !important;
    border-top: none !important;
  }

  @media(min-width: 992px) {
    &:nth-child(1) {
      min-width: 170px;
      padding-left: 15px;
    }
    &:nth-child(2), :nth-child(8) {
      min-width: 140px;
    }
    &:nth-child(7) {
      min-width: 170px;
    }
    &:nth-child(4){
      min-width: 130px;
    }
    &:nth-child(5){
      min-width: 130px;
    }
    &:nth-child(6) {
      min-width: 230px;
    }
    &:nth-child(3) {
      min-width: 200px;
    }
    &:nth-child(9) {
      min-width: 100px;
    }
  }
`;
export const TableGrid = styled(Grid)`
  //margin:  1.5rem;
  overflow-x: auto;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
`;

export const MobileContainer = styled(Box)`
  width: 100%;
  background-color: #fff;
`;

export const SortImg = styled('img')`
  margin-left: 15px;
  padding-bottom: 2px;
`;
