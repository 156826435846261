import React from 'react';

import toast from 'react-hot-toast';
import {
  Container, TestWrapper, CheckImg, Info, ViewItem
} from './styles';

export const showNotification = (message, handleView, position = 'top-center', duration = 5000) => {
  toast.custom(
    () => (
      <Container>
        <TestWrapper>
          <CheckImg />
          <Info>{message?.msg}</Info>
        </TestWrapper>
        <ViewItem onClick={handleView}>
          View
        </ViewItem>
      </Container>
    ),
    {
      position,
      duration,
    }
  );
};
