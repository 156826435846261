import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { TableCell, TableRow } from '@mui/material';

export const ProductDetailMainContainer = styled('div')``;

export const ImageWrapper = styled(Grid)`
  background: none;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  max-height: 100px;
  margin-top: 0rem;
`;

export const ProductDetailImage = styled('img')`
  width: 3rem;
  max-height: 3rem;
  margin-top: 1rem;
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailBrandNameText = styled('p')`
  font-size: 14px;
  font-weight: 700;
  color: #303030;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const MainTableRow = styled(TableRow)`
  display: table-row;
`;

export const BodyCell = styled(TableCell)`
  padding: 1.5rem 0;
`;

export const TableValueText = styled('p')`
  font-size: 15px;
  color: #000000de;
`;

export const ProductDetailFlex = styled('div')`
  display: flex;
  align-items: center;
`;

export const PriceBullet = styled('div')`
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background: #e79800;
`;

export const PriceBox = styled('div')`
  display: flex;
  background: #fff4de;
  border-radius: 30px;
  gap: 5px;
  width: 6.625rem;
  height: 1.313rem;
  margin-top: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const PriceTypo = styled('Typography')`
  font-size: .563rem;
  font-weight: 500;
  color: #e79800;
  margin-top: 2px;
`;
